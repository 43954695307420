<template>
  <div class="market bgcolor">
      <Head :title="$t('home.sheng_market')" :show="true" :bg="true" />
      <div class="market-top"></div>
      <div class="market-main">
            <div class="market-main-add">
                <div class="one-banner" v-if="false">
                    <MySwipe class="banner" id="bone" :swiperoptions="swiperoptions"  >
                        <MySwipeItem v-for="(l,i) in banner" :key="i" >
                                <img class="b-img" v-lazy="$host+l" alt="">   
                                <p class="banner-count">{{i+1}} / {{banner.length}} </p>
                        </MySwipeItem>
                    </MySwipe>
                </div>
                <div class="market-box">             
                    <div class="market-list">
                        <div class="btn-list">
                            <van-button class="recharge-btn" @click="gorecharge(false)">{{$t('wallet.exchange')}}{{$t('common.฿')}}</van-button>
                            <van-button class="recharge-btn" @click="gorecharge(true)">{{$t('wallet.exchange')}}USDT</van-button>
                        </div>
                        <div class="market-item" v-for="(l,i) in tickList" :key="i">
                            <!-- <div v-html="l.Introduction"></div> -->
                            <img class="item-img" alt="" v-lazy="$host+l.image">
                            <div class="item-box">
                                <h3>{{l.name}}</h3>
                                <p class="p-lot">{{$t('home.face_value')}}: ${{l.face_value }}</p>
                                <p class="p-value">{{$t('home.value')}}: {{l.price | number_format}} PV</p>
                                <p class="p-lot">{{$t('home.inventory')}}:{{l.inventory}}</p>
                                <div class="btnlist">
                                    <van-button @click="showDetail(l)" class="btn btn-detail">{{$t('good.detail')}}</van-button>
                                    <van-button @click="payaction(l)" class="btn btn-pay">{{$t('wallet.exchange')}}</van-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>

        <van-popup class="login-popup market-one" v-model="show" position="bottom"   duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('good.good')}}{{$t('good.detail')}}</p>
                    <van-icon name="cross" class="pop-close" @click="show=false" />
                </div>
                <div class="mask-one">
                    <h3>{{item.name}}</h3>
                    <div class="g-detail" v-html="item.Introduction"></div>
                    <template v-if="false">
                        <p>使用方法:</p>
                        <div>
                            微信扫码二维码获取{卡号和密码},复制粘贴至
                            {星巴克APP}点击“福利购”—“我的星礼卡”—右上角
                            “绑定会员星礼卡”输入卡号和密码即可成功充值。  
                        </div>
                    </template>
                </div>
                <!-- <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button> -->
            </div>
        </van-popup>

        <van-popup class="login-popup market-one" v-model="payShow" position="bottom"   duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('wallet.exchange')}} </p>
                    <van-icon name="cross" class="pop-close" @click="payShow=false" />
                </div>
                <div class="mask-two">
                    <div class="payyue item">
                        <span>{{$t('common.yue')}}</span>
                        <span>{{balance | number_format }} PV</span>
                    </div>
                    <div class="paycount item">
                        <span>{{$t('auction.quantity')}}</span>
                        <van-stepper v-model="value"  @change="chaoguoMax"  :max="item.inventory"/>
                    </div>
                    <div class="payvalue item">
                        <span>{{$t('home.value')}}</span>
                        <span>{{value*item.price}} PV</span>
                    </div>
                </div>
                <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button>
            </div>
        </van-popup>


        <van-popup class="login-popup market-one" v-model="rechargeShow" position="bottom"   duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top borderbottomddd">
                    <p v-if="exchargeusdt">{{$t('wallet.exchange')}}USDT</p>
                    <p v-else>{{$t('wallet.exchange')}}{{$t('common.฿')}}</p>
                    <van-icon name="cross" class="pop-close" @click="rechargeShow=false" />
                </div>
                <div class="mask-two">
                    <div>
                        <p class="pvtext">{{$t('add.enter_count')}}</p>
                    </div>
                     <div class=" pvinput">
                        <van-field 
                            v-model.number="amount"
                            :label="''"
                            :placeholder="$t('add.please_amount')"
                            :rules="[{ }]"
                            
                        ></van-field>
                        <p class="pvbid">PV</p>
                    </div>
                    <!-- <p class="pvmin" :class="amount>=100?'':'pverror'">{{$t('add.min_amount')}}100PV</p> -->
                    <div class="pvtips">
                        <p class="pvnum" >{{ amount!=""&&(parseInt(amount)!=amount) ? $t('add.must_num'):''}}</p>
                        <p class="pvmin" :class="amount>=100?'':'pverror'">{{$t('add.min_amount')}}100PV</p>
                    </div>
                </div>
                <div class="btn-box">
                    <div class="btn-box-left">
                        <span>{{$t('add.receive_amount')}} :</span>
                        <span>{{exchargeusdt?'USD ':'฿ '}} </span>
                        <span>{{receive_amount | number_format}}</span>
                    </div>
                    <van-button class="btn-box-right" @click="gotopvexchange">{{$t('wallet.exchange')}}</van-button>
                </div>
            </div>
        </van-popup>

         <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one" v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form label-width="140" class="pay-form" ref="payform" @submit="gotopassexchange">
                <van-field
                v-model="pinpass"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="pin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[{ required: true, message:$t('login.ptian')}]"
                >

                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="submitForm">{{$t('login.confirm')}}</van-button>
            </div>
        </div>
    </van-popup>
        
  </div>
</template>

<script>
import {randomString,myinfo} from "@/common"
import {Toast,Notify} from "vant"

// 1000pv=100usdt=2995 泰铢： 100/0.033389300000 = 2995
export default {
    // mixins:[myinfo],
    data(){
        return {
            pinpass:'',
            pt3:'password',
            secshow:false,
            amount:'',
            
            value:1,
            show:false,
            payShow:false,
            rechargeShow:false,
            item:{},
            banner:[],
            tickList:[],
            balance:0,
            swiperoptions:{
                observer:true,
                // loop:true,
                autoplay:{
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,  
                },
                resistanceRatio:0,
                spaceBetween : 30,
                slidesPerView :1.2,
                 on:{
                    slideChange: function(){
                        // that.current = this.activeIndex;
                    },
                },
            },
            exchargeusdt:false,  // false THB  true usdt 
            trate:''
        }
    },
    computed:{
        receive_amount(){
            return this.exchargeusdt ?  this.amount  / 10  : this.amount / 10 / this.trate ; 
        }
    },
    methods:{
        gotopassexchange(){
            this.$ajax.exchangepvtomoney({
                type:this.exchargeusdt ? 2 : 1,
                pv_amount:this.amount,
                pin:this.pinpass,   // 支付密码
                form_token:randomString()
            }).then(res=>{
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg ,duration:1200});
                    this.rechargeShow = false;
                    this.amount = 1000;
                }
            })
        },
        submitForm(){
            this.$refs.payform.submit()
        },
        changePassType3(){
          this.pt3 = this.pt3 == "password"?"text":"password"
        },
        gotopvexchange(){
            if(parseInt(this.amount)!=this.amount || this.amount<100){
                // 请输入有效的 pv 数额 
                Notify({
                    message: this.$t('add.enter_the_amount'),
                    color: '#ad0000',
                    background: '#ffe1e1',
                });
            }else{
                this.secshow = true;
            }
        },
        gorecharge(flag){
            var access_token = localStorage.getItem("access_token");
            if(access_token){
                if(!this.trate){
                    this.$ajax.walletmy()
                    .then(res=>{
                        if(res.code==200){
                            res.data.list.forEach(item=>{
                                if(item.current_id==8011){
                                    this.trate = item.rate;
                                }
                            
                            })
                        }
                    })
                }
                this.$nextTick(()=>{
                    this.rechargeShow = true;
                    this.exchargeusdt = flag
                })
                
            }else{
                this.$router.push({name:'signin'})
            }
            
        },
        chaoguoMax(){
            if(this.value>this.item.inventory){
                Toast(this.$t('home.tow_more_than'))
            }
        },
        showDetail(item){
            this.item = item;
            this.show = true;
        },
        sureChange(){
            this.$ajax.buyTicket({
                good_id:this.item.id,
                total:this.value,
                form_token:randomString()
            })
            .then(res=>{
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg ,duration:1200});
                    this.payShow = false;
                    // 库存减少
                    this.tickList.forEach((item,idx)=>{
                        if(item.id==this.item.id){
                            item = {...item,inventory:item.inventory-this.value}
                        }
                        this.$set(this.tickList,idx,item);
                    })
                }
            })
        },
        payaction(item){
            var access_token = localStorage.getItem("access_token"); 
            if(access_token){
                this.$ajax.walletmy()
                .then(res=>{
                    if(res.code==200){
                        res.data.list.forEach(item=>{
                            if(item.current_id==1033){
                                this.balance = item.usable_balance
                            }
                        })
                        this.payShow = true;
                        this.item = item;
                        this.value = 1;
                    }
                })
            }else{
                this.$router.push({name:'signin'})
            }
            
        }
    },
    mounted(){
        this.$ajax.livingList()
        .then(res=>{
            if(res.code==200){
                var reg = /^banner/;
                for(var key in res.data.banner[0]){
                    if(reg.test(key)&&res.data.banner[0][key]){
                        this.banner.push(res.data.banner[0][key])
                    }
                }
                this.tickList = res.data.tick;
            }
        })

        
    }
}
</script>
